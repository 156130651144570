import Vue from "vue";
import DialogDefault from "@/components/Content/Dialogs/Default.vue";
import CardActions from "@/components/Content/CardActions.vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import { mapActions, mapGetters } from "vuex";
import { isRequired } from "@/services/rule-services";

export default Vue.extend({
	name: "ListToPrivateDialog",
	model: {},
	props: {
        show: {
            type: Boolean,
            default: false
        }
    },
	components: {
        DialogDefault,
        CardActions,
        CardTextField,
        CardAutocomplete
    },
	data: () => ({
        layer: {
            layer_name: "",
            country_code: null
        },
        countries: [] as {
            id: number,
            value: string
        }[],
		valid: true,
        rules: {
            layer_name: [],
            country_code: []
        }
    }),
	created() {
		this.$nextTick(async () => {
            this.fetchCountries();
        });
	},
	mounted() {},
	computed: {},
	methods: {
        ...mapActions("person", [
            "fetchData"
        ]),

        handleAction(data) {
            switch(data.type) {
                case "save":
                    this.handleSubmit()
                    break;
                case "cancel":
                    this.$emit("close")
                    break;
            }
        },

        async handleSubmit() {
            this.applyRules();

            if(await this.validate()) {
                this.$emit("submit", this.layer)
            };
        },

        async validate() {
            let form = this.$refs.form;
            const valid = await form.validate();
            return valid;
        },

        applyRules() {
            this.rules = {
                layer_name: [isRequired],
                country_code: [isRequired]
            }
        },

        clearRules() {
            this.rules = {
                layer_name: [],
                country_code: []
            }
        },

        /**
         * Fetch all countries from planning
         */
        async fetchCountries() {
            const countries: {
                title: string,
                items: { id: number, value: string }[]
            }[] = await this.fetchData({
                type: "countries",
            })

            this.countries = countries.map(continent => continent.items).flat();
        }
    },
	watch: {
        show() {
            this.clearRules();
        }
    },
});
