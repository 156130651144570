
// import TableList from "./TableList.vue";
import Buttons from "../../Commons/Buttons.vue";
import SnackBar from "../../Commons/SnackBar.vue";
import Vue from "vue";
import { isEmpty, isNull, isUndefined } from "lodash";
import {
	CustomList,
	CustomListFilters,
	CustomListResultPaginate,
} from "../../../../interfaces/custom_list";
import ParamService from "../../../../services/params-service";
// @ts-ignore
import { Paginated, SortingOption } from "@/interfaces/paginated";
import { mapGetters, mapActions } from "vuex";
import {
	prepareTableData,
	prepareSelectedData,
	preparedColumnDefsGrid
} from "../../../../utils/CustomizeViewData";
// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import GridTable from "@/components/Header/Tables/GridTable.vue";
// @ts-ignore
import ActionsTable from "@/components/Header/Tables/ActionsTable.vue"
// @ts-ignore
import FilterGridAG from "@/components/Header/Tables/FilterGridAG.vue"
// @ts-ignore
import ActiveFieldTable from "@/components/Header/Tables/ActiveFieldTable.vue"
import ListToPrivateDialog from "@/views/Admin/CustomList/List/ListToPrivateDialog/index.vue"
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import customListService from "@/services/custom-list-service";
import notificationService from "@/services/notification-service";
import { Notification } from "@/interfaces/proccess";
//@ts-ignore
import { getPermisionCustomList } from "@/utils/permissionResolve.ts";
//@ts-ignore
import NotPermission from "@/views/Errors/not_permission.vue";

export default Vue.extend({
	name: "Lists",
	props: {},
	components: {
		Buttons,
		SnackBar,
		GridTable,
		ActionsTable,
		FilterGridAG,
		ActiveFieldTable,
		ListToPrivateDialog,
		NotPermission
	},
	data: () => ({
		title: "Lists",
		paginated: {
			page: 1,
			limit: 25,
		} as Paginated,
		filters: {},
		options: getDefaultSortingOption("id"),
		table: {
			headers: [],
			items: [],
		},
		fields: {},
		columnRestored: false,
		context: null,
		selectionRows: "single",
		dialogPrivatePois: false,
		custom_list_id: null
	}),
	created() {
		this.$nextTick(async () => {});
	},
	async mounted() {
		await this.getPaginated();
		this.context = { componentParent: this };
	},
	computed: {
		...mapGetters("customize_view", [
			"getFieldByView",
			"getSelectedByView",
		]),
		...mapGetters("internationalization", ["getLanguage"]),
		...mapGetters("profile", ["getAbility"]),

		getResultPaginate(): CustomListResultPaginate {
			return this.$store.state.custom_list.result_paginate;
		},
		getLists(): CustomList[] {
			const result: CustomListResultPaginate = this.getResultPaginate;
			if (
				isUndefined(result) ||
				isNull(result) ||
				isUndefined(result.data) ||
				isNull(result.data)
			) {
				return [];
			}
			return result.data;
		},

		getSizeLists() {
			return this.$t("show.lists", {
				total: this.getLists.length,
			});
		},

		getConfig() {
			return {
				paginated: this.paginated,
				filters: this.filters,
				options: this.options,
				fields: this.fields,
			};
		},

		getConfigColumnDef(){
			return {
				context: this.context,
				eventActive: false,
				appNexus: false,
				entityName: "List",
				redirect: "CustomListEdit",
				activityLog: "",
				edit: true,
				duplicated: false,
				convertLatLong: true,
				log: false,
				flex: 1,
				maxWidthActions: 110,
				permission: this.getPermission
			};
		},

		getColumnsDef(){
			return preparedColumnDefsGrid(this.table.headers, this.getConfigColumnDef);
        },

        getRowsData(){
            if(isEmpty(this.table.items)){
                return [];
            }else{
				return this.table.items;
			}
        },

		canList(){
			return this.getAbility.can(this.getPermission.actions.index, this.getPermission.subject);
		},

		getPermission(){
			return getPermisionCustomList();
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		/** Begin Customize View **/

		...mapActions("customize_view", [
			"setFields",
			"saveSelected",
			"saveColumns",
			"getTableDataSelected",
		]),

		async handleSave(event: any) {
			await this.saveSelected(event);
			await this.saveColumns({ event, columns: this.table.headers });
			await this.getTableData(event);
		},

		async handleUpdateColumns(event: any) {
			await this.saveSelected(event);
		},

		async handleUpdateSizeColumns(params: any) {
			params.forEach(element => {
				let col = this.table.headers.filter(c => c.value == element.col);
				col[0].reactive_width = element.actualWidth;
				col[0].width = String(element.actualWidth) + "px";
			});
			let event = {
				view: params.entity
			};
			await this.saveColumns({ event, columns: this.table.headers });
		},

		async getTableData(
			event: { view: string; isReset: Boolean } = {
				view: "",
				isReset: true,
			}
		) {
			const { selected, fields } = await this.getTableDataSelected({
				route: this.$route?.name,
				entity: this.getLists,
			});

			this.fields = fields;

			const headers: Array<any> = selected.columns;
			const columns: Array<any> = await this.getColumns();

			this.table = await prepareTableData({
				columns: columns,
				fields: this.fields,
				entity: "customList",
				entities: this.getLists,
				isReset: event?.isReset,
			});

			if (isEmpty(headers) || event?.isReset) {
				await this.saveColumns({ event, columns: this.table.headers });
				//this.columnRestored = event?.isReset;
			}

			this.$forceUpdate();
		},

		async getColumns() {
			const selected: any = await this.getSelectedByView(
				this.$route?.name
			);
			const columns: Array<any> = selected.columns;
			return columns ?? [];
		},

		/** End Customize View **/

		async getPaginated() {
			if(this.canList){
				await this.setLoadingData(TypeLoading.loading);
				await this.$store.dispatch(
					"custom_list/paginated",
					await ParamService.getParams(
						this.paginated,
						this.filters,
						this.options
					)
				);
				await this.getTableData({ view: this.$route?.name });
				await this.setLoadingData();
			}
		},
		updatePaginate(data: any) {
			this.paginated.page = data;
		},
		setFilter(params: { key: string | number; value: any }) {
			this.filters = {};
			this.filters[params.key] =
				typeof params.value !== "undefined" ? params.value : "";
		},
		async updateParams(params: {
			filters: CustomListFilters;
			options: SortingOption;
		}) {
			this.filters = params.filters;
			this.options = params.options;
			this.updatePaginate(1);
			await this.getPaginated();
		},
		async selectedLimit(limit: number) {
			this.paginated.limit = limit;
			this.updatePaginate(1);
			await this.getPaginated();
		},
		async selectedOption(params: { options: SortingOption; filter: any }) {
			this.setFilter({ key: params.options.sort, value: params.filter });
			this.updatePaginate(1);
			await this.updateParams({
				filters: this.filters,
				options: params.options,
			});
		},
		async updateCurrentPage(page) {
			this.updatePaginate(page);
			await this.getPaginated();
		},
		async removeOption() {
			this.filters = {};
			this.options = getDefaultSortingOption();
			this.updatePaginate(1);
			await this.getPaginated();
		},
		async handleResetColumns() {
			await this.getTableData({ view: this.$route?.name, isReset: true });
		},
		shouldShowConvertLatLong(custom_list_id: number) {
			const isLatLong = this.getLists.find(list => list.id == custom_list_id)?.type_key == "lat_long";
			return isLatLong;
		},

		async handleConvertPrivatePois(data) {
			await this.setLoadingData(TypeLoading.loading);

			this.closeDialogPrivatePois();

			try {
				await customListService.convertToPrivatePois({
					...data,
					custom_list_id: this.custom_list_id
				});

				notificationService.notifySuccess(this.$store, {} as Notification);
				this.setLoadingData();
			} catch(e) {
				notificationService.notifyError(this.$store, {
					// @ts-ignore
					details: e.errors,					
					// @ts-ignore
					message: e.message
				} as Notification);
				this.setLoadingData();
			}
		},

		openDialogPrivatePois(param: {
			id: string
		}) {
			this.dialogPrivatePois = true;
			this.custom_list_id = param.id;
		},

		closeDialogPrivatePois() {
			this.dialogPrivatePois = false;
		}
	},
	watch: {
		"paginated.page"(val, old) {
			if (val !== old) {
				this.getPaginated();
			}
		},
		async getLanguage(lang) {
			await this.handleResetColumns();
			this.$forceUpdate();
		},
	},
});
